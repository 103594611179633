// Import the required modules from React and React Native
import React, { useState, useContext, createContext } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthProvider } from './AuthContext';
import { SafeAreaView, Text, StyleSheet, View } from 'react-native';
import MainScreen from './MainScreen';
import SubpageDelay from './screens/SubpageDelay';
import SubpageLoaded from './screens/SubpageLoaded';
import SubpageUnloaded from './screens/SubpageUnloaded';
import SubpageUpload from './screens/SubpageUpload';
import SubpageMain from './screens/SubpageMain';
import SubpageLogin from './screens/SubpageLogin';
import SubpageBuild from './screens/SubpageBuild';
import SuperUserPage from './screens/SubpageSuperUserPage';

import { Link } from 'react-router-dom';
import { StatusBar } from 'expo-status-bar';
const Stack = createStackNavigator();
export default function App() {
  return (

      <AuthProvider>

        <NavigationContainer>
          <Stack.Navigator initialRouteName="Login">
            <Stack.Screen name="Home" component={SubpageMain} />
            <Stack.Screen name="Login" component={SubpageLogin} />
            <Stack.Screen name="Loaded" component={SubpageLoaded} />
            <Stack.Screen name="Unloaded" component={SubpageUnloaded} />
            <Stack.Screen name="Upload CMR" component={SubpageUpload} />
            <Stack.Screen name="Delayed" component={SubpageDelay} />
            {/* <Stack.Screen name="Loaded" component={SubpageLoaded} />
            <Stack.Screen name="Unloaded" component={SubpageUnloaded} />
            <Stack.Screen name="Upload CMR" component={SubpageUpload} />
            <Stack.Screen name="Delayed" component={SubpageDelay} />
            <Stack.Screen name="Build" component={SubpageBuild} />
            <Stack.Screen name="SuperUserPage" component={SuperUserPage} /> */}
          </Stack.Navigator>
        </NavigationContainer>

      </AuthProvider>
   
  );
}






// // Import the required modules from React and React Native
// import React , { useState, useContext, createContext } from 'react';
// // import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
// import { NavigationContainer } from '@react-navigation/native';
// import { createStackNavigator } from '@react-navigation/stack';
// import { AuthProvider } from './AuthContext';  
// import { SafeAreaView, Text, StyleSheet,View } from 'react-native';

// import MainScreen from './MainScreen';
// import SubpageDelay from './screens/SubpageDelay';
//  import SubpageLoaded from './screens/SubpageLoaded';
// import SubpageUnloaded from './screens/SubpageUnloaded';
// import SubpageUpload from './screens/SubpageUpload';
// import SubpageMain from './screens/SubpageMain';
// import SubpageLogin from './screens/SubpageLogin';
// import SubpageBuild from './screens/SubpageBuild';
// import SuperUserPage from './screens/SubpageSuperUserPage';

// import { Link } from 'react-router-dom';
// import { StatusBar } from 'expo-status-bar';
// const Stack = createStackNavigator();

// // Create a functional component called `App`
// export default function App() {
//   return (
//     <View>{Hello}</View>
//   // <AuthProvider> 
//   //   <NavigationContainer>
//   //     <Stack.Navigator initialRouteName="Login">
//   //       <Stack.Screen name="Home" component={SubpageMain} />
//   //       <Stack.Screen name="Login" component={SubpageLogin} />
//   //       <Stack.Screen name="Loaded" component={SubpageLoaded} />
//   //       <Stack.Screen name="Unloaded" component={SubpageUnloaded} />
//   //       <Stack.Screen name="Upload CMR" component={SubpageUpload} />
//   //       <Stack.Screen name="Delayed" component={SubpageDelay} />
//   //       <Stack.Screen name="Build" component={SubpageBuild} />
//   //       <Stack.Screen name="SuperUserPage" component={SuperUserPage} />
//   //     </Stack.Navigator>
//   //   </NavigationContainer>
//   // </AuthProvider>
//   );
// }
// // Create a StyleSheet to add styles to the components
// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
// });


